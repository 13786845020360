import actionList from '@/libs/acl_roles/actionList'

const { ACTION_VIEW, ACTION_CREATE, ACTION_UPDATE } = actionList

export default [

  // *===============================================---*
  // *--------- Hardware pages ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/ipam/list/ipv4',
    name: 'ipam-list-ipv4',
    component: () => import('@/views/apps/ipam/ipv4/Index.vue'),
    meta: {
      resource: 'subnets',
      action: ACTION_VIEW,
      pageTitle: 'IPv4',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ipam/ipv4/create',
    name: 'ipam-create',
    component: () => import('@/views/apps/ipam/Create.vue'),
    meta: {
      resource: 'subnets',
      action: ACTION_CREATE,
      navActiveLink: 'ipam-list-ipv4',
      pageTitle: 'Create IPv4',
      breadcrumb: [
        {
          text: 'List',
          to: '/ipam/list/ipv4',
        },
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ipam/ipv4/update/:id',
    name: 'ipam-update-ipv4',
    component: () => import('@/views/apps/ipam/Update.vue'),
    meta: {
      resource: 'subnets',
      action: ACTION_UPDATE,
      navActiveLink: 'ipam-list-ipv4',
      pageTitle: 'Update IPv4',
      breadcrumb: [
        {
          text: 'List',
          to: '/ipam/list/ipv4',
        },
        {
          text: 'Update',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ipam/list/ipv6',
    name: 'ipam-list-ipv6',
    component: () => import('@/views/apps/ipam/ipv6/Index.vue'),
    meta: {
      resource: 'subnets',
      action: ACTION_VIEW,
      pageTitle: 'IPv6',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ipam/ipv6/create',
    name: 'ipam-create',
    component: () => import('@/views/apps/ipam/Create.vue'),
    meta: {
      resource: 'subnets',
      action: ACTION_CREATE,
      navActiveLink: 'ipam-list-ipv6',
      pageTitle: 'Create IPv6',
      breadcrumb: [
        {
          text: 'List',
          to: '/ipam/list/ipv6',
        },
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ipam/ipv6/update/:id',
    name: 'ipam-update-ipv6',
    component: () => import('@/views/apps/ipam/Update.vue'),
    meta: {
      resource: 'subnets',
      action: ACTION_UPDATE,
      navActiveLink: 'ipam-list-ipv6',
      pageTitle: 'Update IPv6',
      breadcrumb: [
        {
          text: 'List',
          to: '/ipam/list/ipv6',
        },
        {
          text: 'Update',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ipam/categories',
    name: 'ipam-categories',
    component: () => import('@/views/apps/ipam/categories/Index.vue'),
    meta: {
      resource: 'subnet-categories',
      action: ACTION_VIEW,
      pageTitle: 'Categories',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ipam/categories/create',
    name: 'categories-create',
    component: () => import('@/views/apps/ipam/categories/Create.vue'),
    meta: {
      resource: 'subnet-categories',
      action: ACTION_CREATE,
      navActiveLink: 'ipam-categories',
      pageTitle: 'Create Category',
      breadcrumb: [
        {
          text: 'List',
          to: '/ipam/categories',
        },
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ipam/categories/update/:id',
    name: 'categories-update',
    component: () => import('@/views/apps/ipam/categories/Update.vue'),
    meta: {
      resource: 'subnet-categories',
      action: ACTION_UPDATE,
      navActiveLink: 'ipam-categories',
      pageTitle: 'Update Category',
      breadcrumb: [
        {
          text: 'List',
          to: '/ipam/categories',
        },
        {
          text: 'Update',
          active: true,
        },
      ],
    },
  },
]
