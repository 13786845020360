import actionList from '@/libs/acl_roles/actionList'

const { ACTION_VIEW, ACTION_CREATE, ACTION_UPDATE } = actionList

export default [

  // *===============================================---*
  // *--------- Hardware pages ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/tariffs/list',
    name: 'tariffs-list',
    component: () => import('@/views/apps/tariffs/Index.vue'),
    meta: {
      resource: 'tariffs',
      action: ACTION_VIEW,
      pageTitle: 'RADIUS Tariffs',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tariffs/create',
    name: 'tariffs-create',
    component: () => import('@/views/apps/tariffs/Create.vue'),
    meta: {
      resource: 'tariffs',
      action: ACTION_CREATE,
      navActiveLink: 'tariffs-list',
      pageTitle: 'Create tariff',
      breadcrumb: [
        {
          text: 'List',
          to: '/tariffs/list',
        },
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tariffs/update/:id',
    name: 'tariffs-update',
    component: () => import('@/views/apps/tariffs/Update.vue'),
    meta: {
      resource: 'tariffs',
      action: ACTION_UPDATE,
      navActiveLink: 'tariffs-list',
      pageTitle: 'Update tariff',
      breadcrumb: [
        {
          text: 'List',
          to: '/tariffs/list',
        },
        {
          text: 'Update',
          active: true,
        },
      ],
    },
  },
]
