import hardwareRoutes from './groups/hardware'
import inviteRoutes from './groups/invite'
import siteRoutes from './groups/sites'
import userManagementRoutes from './groups/user-management'
import profileRoutes from './groups/profile'
import tariffRoutes from './groups/tariffs'
import nasRoutes from './groups/nas'
import vpnDialupsRoutes from './groups/vpn-dialups'
import ipamRoutes from './groups/ipam'
import servicesRoutes from './groups/services'
import companyRoutes from './groups/company-management'
import companyProfile from './groups/company-profile'
import licenceRoutes from './groups/licence'

export default [
  // *===============================================---*
  // *--------- Home page ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      pageTitle: 'Dashboard',
    },
  },
  ...hardwareRoutes,
  ...inviteRoutes,
  ...siteRoutes,
  ...userManagementRoutes,
  ...profileRoutes,
  ...tariffRoutes,
  ...nasRoutes,
  ...vpnDialupsRoutes,
  ...ipamRoutes,
  ...servicesRoutes,
  ...companyRoutes,
  ...companyProfile,
  ...licenceRoutes,
  // *===============================================---*
  // *--------- Error page ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      pageTitle: 'Error',
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]
