import axios from '@axios'

const getDefaultState = () => ({
  items: [],
})

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {},
  mutations: {
    SET_ITEMS(state, payload) {
      state.items = payload
    },
    DELETE_ITEM(state, id) {
      state.items = state.items.filter(item => (item.id !== id))
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    async getItems({ commit }, id) {
      try {
        const { data } = await axios.get(`/site/${id}/wifi/registration/index`)
        commit('SET_ITEMS', data.data)

        return data.data
      } catch (error) {
        commit('SET_ITEMS', [])
        return Promise.reject(error)
      }
    },
    async deleteItem({ commit }, { siteId, ids }) {
      try {
        await axios.delete(`/site/${siteId}/wifi/registration/destroy`, {
          data: {
            ids: [...ids],
          },
        })

        commit('DELETE_ITEM', ids[0])
        return true
      } catch (error) {
        commit('RESET_ITEM', [])
        return Promise.reject(error)
      }
    },
  },
}
