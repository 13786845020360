import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueMask from 'v-mask'
import VueCompositionAPI from '@vue/composition-api'
import { io } from 'socket.io-client'
import VueSocketIOExt from 'vue-socket.io-extended'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/vue-select'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Mask Plugin Mask
Vue.use(VueMask)

// Composition API
Vue.use(VueCompositionAPI)

const socket = io('ws://127.0.0.1:5200', {
  autoConnect: false,
  transports: ['websocket'],
  reconnectionDelayMax: 10000,
  query: {
    token: localStorage.getItem('accessToken'),
  },
  // autoConnect: false
})

Vue.use(VueSocketIOExt, socket)
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
