export default [
  // *===============================================---*
  // *--------- Authentication Pages ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/authentication/Login.vue'),
    meta: {
      pageTitle: 'Login',
      resource: 'Auth',
      layout: 'full',
    },
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import('@/views/apps/legal/Index.vue'),
    meta: {
      pageTitle: 'Legal',
      layout: 'full',
    },
  },
  {
    path: '/latest',
    name: 'latest',
    component: () => import('@/views/apps/legal/Index.vue'),
    meta: {
      pageTitle: 'Legal / Latest',
      layout: 'full',
    },
  },
  {
    path: '/register/:token?/:id?',
    name: 'auth-register',
    component: () => import('@/views/authentication/Register.vue'),
    meta: {
      pageTitle: 'Register',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/authentication/ForgotPassword.vue'),
    meta: {
      pageTitle: 'Forgot password',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/reset-password/:token/:id',
    name: 'auth-reset-password',
    component: () => import('@/views/authentication/ResetPassword.vue'),
    meta: {
      pageTitle: 'Reset password',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/verify-email/:token/:id',
    name: 'auth-activate-email',
    component: () => import('@/views/authentication/ActivateEmail.vue'),
    meta: {
      pageTitle: 'Activate email',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
]
