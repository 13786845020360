export default [
  // *===============================================---*
  // *--------- Profile pages ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/company-profile',
    name: 'company-profile',
    component: () => import('@/views/apps/company-profile/view/View.vue'),
    meta: {
      pageTitle: 'Company Profile',
    },
  },
  {
    path: '/company-profile/update-info',
    name: 'company-profile-update-info',
    component: () => import('@/views/apps/company-profile/edit/UpdateInfo.vue'),
    meta: {
      pageTitle: 'Update info',
      breadcrumb: [
        {
          text: 'Company Profile',
          to: '/company-profile',
          pageTitle: 'Models',
        },
        {
          text: 'Update',
          active: true,
        },
      ],
    },
  },
]
