import axios from '@axios'

const getDefaultState = () => ({
  items: [],
  reservations: [],
})

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {},
  mutations: {
    SET_ITEMS(state, payload) {
      state.items = payload
    },
    SET_RESERVATIONS_ITEMS(state, payload) {
      state.reservations = payload
    },
    UPDATE_ITEM(state, payload) {
      state.items = state.items.map(item => {
        if (item.id === payload.id) {
          return payload
        }

        return item
      })
    },
    UPDATE_ITEM_RESERVATION(state, payload) {
      state.reservations = state.reservations.map(item => {
        if (item.id === payload.id) {
          return payload
        }

        return item
      })
    },
    DELETE_ITEM(state, id) {
      state.items = state.items.filter(item => (item.id !== id))
    },
    DELETE_ITEM_RESERVATION(state, id) {
      state.reservations = state.reservations.filter(item => (item.id !== id))
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    async getLeases({ commit }, id) {
      try {
        const { data } = await axios.get(`/site/${id}/network/active-lease/index`)
        commit('SET_ITEMS', data.data)

        return data.data
      } catch (error) {
        commit('SET_ITEMS', [])
        return Promise.reject(error)
      }
    },
    async getReservations({ commit }, id) {
      try {
        const { data } = await axios.get(`/hardware/control/${id}/ip/dhcp-server/lease/index?dinamic=0`)
        commit('SET_RESERVATIONS_ITEMS', data.data)

        return data.data
      } catch (error) {
        commit('SET_RESERVATIONS_ITEMS', [])
        return Promise.reject(error)
      }
    },
    async updateItem({ commit }, { id, payload, isStatic }) {
      try {
        await axios.put(`/hardware/control/${id}/ip/dhcp-server/lease/update`, {
          ...payload,
        })

        if (isStatic) {
          commit('UPDATE_ITEM_RESERVATION', payload)
        } else {
          commit('UPDATE_ITEM', payload)
        }
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async deleteItem({ commit }, { id, hardwareId, isStatic }) {
      const params = new URLSearchParams()
      params.append('id', id)
      try {
        await axios.delete(`/hardware/control/${hardwareId}/ip/dhcp-server/lease/destroy`, { params }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        if (isStatic) {
          commit('DELETE_ITEM_RESERVATION', id)
        } else {
          commit('DELETE_ITEM', id)
        }
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
