import axios from '@axios'

const getDefaultState = () => ({
  items: [],
  searchFields: '',
  orderBy: '',
  sortedBy: '',
  pagination: {
    current_page: 1,
    last_page: 1,
    per_page: 25,
    total: 0,
  },
})

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {},
  mutations: {
    SET_ITEMS(state, payload) {
      state.items = payload
    },
    UPDATE_ITEM(state, payload) {
      state.items = state.items.map(item => {
        if (item.id === payload.id) {
          return payload
        }

        return item
      })
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    SET_SEARCH_FIELDS(state, fields) {
      state.searchFields = fields
    },
    SET_ORDER_BY(state, order) {
      state.orderBy = order
    },
    DELETE_ITEM(state, id) {
      state.items = state.items.filter(item => (item.id !== id))
    },
    SET_SORTED_BY(state, field) {
      state.sortedBy = field
    },
  },
  actions: {
    setSearchFields({ commit }, payload) {
      commit('SET_SEARCH_FIELDS', payload)
    },

    setOrderBy({ commit }, payload) {
      commit('SET_ORDER_BY', payload)
    },
    setSortedBy({ commit }, payload) {
      commit('SET_SORTED_BY', payload)
    },
    async getItems({ commit }, id) {
      try {
        const { data } = await axios.get(`/site/${id}/wifi/access-point/index`)
        commit('SET_ITEMS', data.data)

        return data.data
      } catch (error) {
        commit('SET_ITEMS', [])
        return Promise.reject(error)
      }
    },
    async updateItem({ commit }, { siteId, isNewCapsMan, ...payload }) {
      try {
        const { data } = await axios.put(`/site/${siteId}/wifi/${isNewCapsMan ? 'new-' : ''}access-point/update`, payload.payload)

        commit('UPDATE_ITEM', data.data)

        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async updateAccessPointStatusEnable({ commit }, { siteId, ids }) {
      try {
        await axios.put(`/site/${siteId}/wifi/access-point/enable`, {
          ids: [...ids],
        })

        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async updateAccessPointStatusDisable({ commit }, { siteId, ids }) {
      try {
        await axios.put(`/site/${siteId}/wifi/access-point/disable`, {
          ids: [...ids],
        })

        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
