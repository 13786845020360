/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import axios from '@axios'

const getDefaultState = () => ({
  items: [],
  controller_status: null,
  encryptionList: [],
})

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {},
  mutations: {
    SET_ITEMS(state, payload) {
      const { data, options } = payload
      state.controller_status = options.controller_status
      state.items = data
      state.encryptionList = options.wifiEncryptions.data
    },
    SET_ITEM(state, payload) {
      if (state.items.push) {
        state.items.push(payload)
      }
    },
  },
  actions: {
    async getItems({ commit }, id) {
      try {
        const { data } = await axios.get(`/site/${id}/wifi/setting/index`)
        commit('SET_ITEMS', data)

        return data.data
      } catch (error) {
        commit('SET_ITEMS', [])
        return Promise.reject(error)
      }
    },
    async createItem({ commit }, { siteId, ...payload }) {
      try {
        const { data } = await axios.post(`/site/${siteId}/wifi/setting/store`, payload.payload)
        commit('SET_ITEM', data.data)
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async updateItem({ commit }, { siteId, ...payload }) {
      try {
        await axios.put(`/site/${siteId}/wifi/setting/${payload.payload.id}`, payload.payload)

        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async updateItemStatus({ commit }, { siteId, ...payload }) {
      try {
        await axios.put(`/site/${siteId}/wifi/setting/controller-change-status`, payload.payload)

        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async pullItem({ commit }, siteId) {
      try {
        await axios.put(`/site/${siteId}/wifi/setting/pull`)

        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async pushItem({ commit }, siteId) {
      try {
        await axios.put(`/site/${siteId}/wifi/setting/push`)

        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async reProvisionItem({ commit }, siteId) {
      try {
        await axios.put(`/site/${siteId}/wifi/setting/re-provision`)

        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
