import actionList from '@/libs/acl_roles/actionList'

const { ACTION_VIEW, ACTION_CREATE, ACTION_UPDATE } = actionList

export default [

  // *===============================================---*
  // *--------- Hardware pages ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/nas/list',
    name: 'nas-list',
    component: () => import('@/views/apps/nas/Index.vue'),
    meta: {
      resource: 'nas',
      action: ACTION_VIEW,
      pageTitle: 'NAS',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nas/create',
    name: 'nas-create',
    component: () => import('@/views/apps/nas/Create.vue'),
    meta: {
      resource: 'nas',
      action: ACTION_CREATE,
      navActiveLink: 'nas-list',
      pageTitle: 'Create NAS',
      breadcrumb: [
        {
          text: 'List',
          to: '/nas/list',
        },
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nas/update/:id',
    name: 'nas-update',
    component: () => import('@/views/apps/nas/Update.vue'),
    meta: {
      resource: 'nas',
      action: ACTION_UPDATE,
      navActiveLink: 'nas-list',
      pageTitle: 'Update NAS',
      breadcrumb: [
        {
          text: 'List',
          to: '/nas/list',
        },
        {
          text: 'Update',
          active: true,
        },
      ],
    },
  },
]
