import axios from '@axios'

export default {
  namespaced: true,
  state: {
    countryList: [],
    defaultIso: null,
  },
  getters: {},
  mutations: {
    SET_COUNTRY_LIST(state, data) {
      state.countryList = data
    },
    SET_DEFAULT_ISO(state, data) {
      state.defaultIso = data
    },
  },
  actions: {
    async fetchCountryList({ commit }) {
      try {
        const { data } = await axios.get('/country/list')

        commit('SET_COUNTRY_LIST', data.data)
        commit('SET_DEFAULT_ISO', data.default_iso)
        return data.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
