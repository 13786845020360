import axios from '@axios'
import { getField, updateField } from 'vuex-map-fields'

const getDefaultState = () => ({
  simrush: null,
  masterDistributor: null,
  distributor: null,
  reseller: null,
  client: null,
  site: null,
  hardware: null,
  companiesList: [],
  masterDistributorList: [],
  distributorList: [],
  resellerList: [],
  clientList: [],
  siteList: [],

  clients: [],
  distributors: [],
  masterDistributors: [],
  resellers: [],
})

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    getField,
  },
  mutations: {
    SET_COMPANIES_LIST(state, payload) {
      if (payload.company_type === 'master-distributor') {
        state.masterDistributorList = payload.list
        state.masterDistributors = payload.list
      } else if (payload.company_type === 'distributor') {
        state.distributorList = payload.list
        state.distributors = payload.list
      } else if (payload.company_type === 'reseller') {
        state.resellerList = payload.list
        state.resellers = payload.list
      } else {
        state.clientList = payload.list
        state.clients = payload.list
      }
    },
    SELECT_COMPANIES(state, list) {
      const entityList = [
        'simrush', 'masterDistributor', 'master_distributor', 'distributor', 'reseller', 'client', 'site', 'hardware', 'clients', 'distributors', 'masterDistributors', 'resellers',
      ]

      entityList.forEach(entity => {
        if (list[entity]) {
          const listProperty = entity === 'master_distributor' ? 'masterDistributorList' : `${entity}List`
          if (entity === 'master_distributor') {
            state.masterDistributor = list[entity]
          }
          state[entity] = list[entity]
          state[listProperty] = [list[entity]]
        }
      })

      const entityChildrenList = [
        'distributors', 'resellers', 'clients',
      ]

      entityChildrenList.forEach(entity => {
        if (list[entity]) {
          if (entity === 'distributors') {
            state.distributorList = list[entity]
          } else if (entity === 'resellers') {
            state.resellerList = list[entity]
          } else if (entity === 'clients') {
            state.clientList = list[entity]
          }
        }
      })
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    updateField,
  },
  actions: {
    async fetchCompaniesList({ commit }, { ...payload }) {
      try {
        const { data } = await axios.get('/hardware/companies/list', { params: { ...payload } })
        commit('SET_COMPANIES_LIST', ({
          list: data.data,
          company_type: payload.company_type,
        }))

        return data.data
      } catch (error) {
        commit('SET_COMPANIES_LIST', [])
        return Promise.reject(error)
      }
    },
    async fetchHardwareSelectedCompany({ commit }, { ...payload }) {
      try {
        const { data } = await axios.get('/hardware/listAutocomplete', { params: { ...payload } })

        commit('SELECT_COMPANIES', data.data)

        return data.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchLicenceSelectedCompany({ commit }, { ...payload }) {
      try {
        const { data } = await axios.get('/licence/listAutocomplete', { params: { ...payload } })

        commit('SELECT_COMPANIES', data.data)

        return data.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
