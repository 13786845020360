import axios from '@axios'
import { getField, updateField } from 'vuex-map-fields'

const getDefaultState = () => ({
  billingTerms: [],
  companyRoles: [],
  userRoles: [],
  parentCompanyList: [],
  companyList: [],
  items: [],
  statuses: [],
  roles: [],
  searchFields: '',
  orderBy: '',
  sortedBy: '',
  pagination: {
    current_page: 1,
    last_page: 1,
    per_page: 25,
    total: 0,
  },
})

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    getField,
  },
  mutations: {
    SET_BILLING_TERMS_LIST(state, list) {
      state.billingTerms = list
    },
    SET_COMPANY_ROLES_LIST(state, list) {
      state.companyRoles = list
    },
    SET_USER_ROLES_LIST(state, list) {
      state.userRoles = list
    },
    SET_PARENT_COMPANY_LIST(state, list) {
      state.parentCompanyList = list
    },
    SET_COMPANY_LIST(state, list) {
      state.companyList = list
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    updateField,
    SET_ITEMS(state, payload) {
      state.items = payload
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload
    },
    SET_SEARCH_FIELDS(state, fields) {
      state.searchFields = fields
    },
    SET_ORDER_BY(state, order) {
      state.orderBy = order
    },
    SET_SORTED_BY(state, field) {
      state.sortedBy = field
    },
    SET_STATUSES(state, payload) {
      state.statuses = payload.data.map(item => ({
        value: item.id,
        text: item.name,
      }))
    },
    SET_ROLES(state, payload) {
      state.roles = payload.data.map(item => ({
        value: item.id,
        text: item.name,
      }))
    },
  },
  actions: {
    async fetchBillingTerms({ commit }) {
      try {
        const { data } = await axios.get('/user/invite/billing-terms/list')

        commit('SET_BILLING_TERMS_LIST', data.data)

        return data.data
      } catch (error) {
        commit('SET_BILLING_TERMS_LIST', [])
        return Promise.reject(error)
      }
    },
    async fetchCompanyRoles({ commit }) {
      try {
        const { data } = await axios.get('/user/invite/company-roles/list')

        commit('SET_COMPANY_ROLES_LIST', data.data)

        return data.data
      } catch (error) {
        commit('SET_COMPANY_ROLES_LIST', [])
        return Promise.reject(error)
      }
    },
    async fetchUserRoles({ commit }) {
      try {
        const { data } = await axios.get('/user/invite/user-roles/list')

        commit('SET_USER_ROLES_LIST', data.data)

        return data.data
      } catch (error) {
        commit('SET_USER_ROLES_LIST', [])
        return Promise.reject(error)
      }
    },
    async fetchParentCompanyList({ commit }, payload) {
      try {
        const { data } = await axios.get(`/company/list?query=${payload.query}&type_id=${payload.id}`)

        commit('SET_PARENT_COMPANY_LIST', data.data)

        return data.data
      } catch (error) {
        commit('SET_PARENT_COMPANY_LIST', [])
        return Promise.reject(error)
      }
    },
    async fetchCompanyList({ commit }, payload) {
      try {
        const { data } = payload.query ? await axios.get(`/company/list?query=${payload.query}`) : await axios.get('/company/list')

        commit('SET_COMPANY_LIST', data.data)

        return data.data
      } catch (error) {
        commit('SET_COMPANY_LIST', [])
        return Promise.reject(error)
      }
    },
    async getItems({ commit }, { ...payload }) {
      try {
        const { data } = await axios.get('/user/invite/index', { params: { ...payload } })
        const { pagination } = data.meta
        const { options } = data
        const items = data.data

        const {
          searchFields, search, orderBy, sortedBy,
        } = payload

        if (searchFields && search) {
          commit('SET_SEARCH_FIELDS', searchFields)
        }

        if (orderBy && sortedBy) {
          commit('SET_ORDER_BY', orderBy)
          commit('SET_SORTED_BY', sortedBy)
        }

        commit('SET_ITEMS', items)
        commit('SET_PAGINATION', pagination)
        commit('SET_STATUSES', options?.statuses ?? [])
        commit('SET_ROLES', options?.roles ?? [])

        return data.data
      } catch (error) {
        commit('SET_ITEMS', [])
        return Promise.reject(error)
      }
    },
    setSearchFields({ commit }, payload) {
      commit('SET_SEARCH_FIELDS', payload)
    },
    setOrderBy({ commit }, payload) {
      commit('SET_ORDER_BY', payload)
    },
    setSortedBy({ commit }, payload) {
      commit('SET_SORTED_BY', payload)
    },
    async resendInvite({ commit }, id) {
      await axios.post(`/user/invite/${id}/re-send`)
      commit('SET_ITEMS', [])
    },
    async cancelInvite({ commit }, id) {
      await axios.delete(`/user/invite/${id}`)
      commit('SET_ITEMS', [])
    },
  },
}
