export default [

  // *===============================================---*
  // *--------- Hardware pages ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/licence/list',
    name: 'licence-list',
    component: () => import('@/views/apps/licence/Index.vue'),
    meta: {
      resource: 'licences',
      action: 'view-index',
      pageTitle: 'Licence Info',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/licence/create',
    name: 'licence-create',
    component: () => import('@/views/apps/licence/Create.vue'),
    meta: {
      resource: 'licences',
      action: 'create',
      navActiveLink: 'licence-list',
      pageTitle: 'Create licence',
      breadcrumb: [
        {
          text: 'List',
          to: '/licence/list',
          pageTitle: 'Models',
        },
        {
          text: 'Add/Create Single Licence Page',
          active: true,
        },
      ],
    },
  },
  {
    path: '/licence/create/bulk',
    name: 'licence-create-bulk',
    component: () => import('@/views/apps/licence/CreateBulk.vue'),
    meta: {
      resource: 'licences',
      action: 'create',
      navActiveLink: 'licence-list',
      pageTitle: 'Create licence',
      breadcrumb: [
        {
          text: 'List',
          to: '/licence/list',
          pageTitle: 'Models',
        },
        {
          text: 'Add/Create Bulk Licence Page',
          active: true,
        },
      ],
    },
  },
  {
    path: '/licence/update/:id',
    name: 'licence-update',
    component: () => import('@/views/apps/licence/Update.vue'),
    meta: {
      resource: 'licences',
      action: 'update',
      navActiveLink: 'licence-list',
      pageTitle: 'Update licence',
      breadcrumb: [
        {
          text: 'List',
          to: '/licence/list',
        },
        {
          text: 'Update',
          active: true,
        },
      ],
    },
  },
]
