import axios from '@axios'

const getDefaultState = () => ({
  items: [],
  interfaces: [],
  traffic: [],
})

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {},
  mutations: {
    SET_ITEMS(state, payload) {
      state.items = payload
    },
    ADD_TRAFFIC(state, payload) {
      state.traffic = state.traffic.concat(payload)
    },
    SET_INTERFACES(state, payload) {
      state.interfaces = payload
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    async getItems({ commit }, id) {
      try {
        const { data } = await axios.get(`/hardware/control/${id}/interface/statistic/show`)

        if (data.data?.interfaces) {
          commit('SET_INTERFACES', data.data.interfaces)
        }

        commit('SET_ITEMS', data.data)

        return data.data
      } catch (error) {
        console.error(error)
        // commit('SET_ITEMS', [])
        // return Promise.reject(error)
        return []
      }
    },
    async getStatisticItems({ commit }, id) {
      try {
        const { data } = await axios.get(`/hardware/control/${id}/interface/statistic/show?type=onlyTraffic`)

        if (data.data?.interfaces) {
          commit('SET_INTERFACES', data.data.interfaces)
        }

        commit('ADD_TRAFFIC', data.data.traffic)

        return data.data
      } catch (error) {
        console.error(error)
        // commit('SET_ITEMS', [])
        // return Promise.reject(error)
        return []
      }
    },
  },
}
