import axios from '@axios'
import router from '@/router'

const getDefaultState = () => ({
  items: [],
  availableToSiteItems: [],
  searchFields: '',
  orderBy: '',
  sortedBy: '',
  pagination: {
    current_page: 1,
    last_page: 1,
    per_page: 25,
    total: 0,
  },
})

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {},
  mutations: {
    SET_ITEMS(state, payload) {
      state.items = payload
    },
    SET_AVAILABLE_TO_SITE_ITEMS(state, payload) {
      state.availableToSiteItems = payload
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload
    },
    SET_SEARCH_FIELDS(state, fields) {
      state.searchFields = fields
    },
    SET_ORDER_BY(state, order) {
      state.orderBy = order
    },
    SET_SORTED_BY(state, field) {
      state.sortedBy = field
    },
    DELETE_ITEM(state, id) {
      state.items = state.items.filter(item => (item.id !== id))
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    UPDATE_ITEMS_STATUS(state, payload) {
      if (payload.length) {
        payload.forEach(item => {
          state.items.find(itemExists => itemExists.id === item.id).is_online = item.is_online
        })
      }
    },
  },
  actions: {
    async getItems({ commit }, { ...payload }) {
      try {
        const { data } = await axios.get(`/site/${router.currentRoute.params.id}/hardware/index`, { params: { ...payload } })
        const { pagination } = data.meta

        const {
          searchFields, search, orderBy, sortedBy,
        } = payload

        if (searchFields && search) {
          commit('SET_SEARCH_FIELDS', searchFields)
        }

        if (orderBy && sortedBy) {
          commit('SET_ORDER_BY', orderBy)
          commit('SET_SORTED_BY', sortedBy)
        }

        commit('SET_ITEMS', data.data)
        commit('SET_PAGINATION', pagination)

        return data.data
      } catch (error) {
        commit('SET_ITEMS', [])
        return Promise.reject(error)
      }
    },
    async updateStatuses({ commit }, id) {
      try {
        const { data } = await axios.put(`/site/${id}/refresh-hardware`)

        const items = data.data.map(item => ({
          ...item,
          ...{ telephone: `${item.phone_country_code ? `+${item.phone_country_code}` : ''}${item.phone_number ?? ''}` },
        }))

        commit('SET_ITEMS', items)

        return data.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getAvailableToSiteItems({ commit }) {
      try {
        const { data } = await axios.get('site/hardware/index')

        commit('SET_AVAILABLE_TO_SITE_ITEMS', data.data)

        return data.data
      } catch (error) {
        commit('SET_AVAILABLE_TO_SITE_ITEMS', [])
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async setHardwaresToSite({ commit }, payload) {
      try {
        await axios.put(`/site/${payload.siteId}/hardware/add`, { hardwareIds: [...payload.hardwareIds] })
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async deassociateItem({ commit }, id) {
      try {
        await axios.put('/hardware/deassociation', {
          ids: [id],
        })
        commit('DELETE_ITEM', id)
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    setSearchFields({ commit }, payload) {
      commit('SET_SEARCH_FIELDS', payload)
    },

    setOrderBy({ commit }, payload) {
      commit('SET_ORDER_BY', payload)
    },
    setSortedBy({ commit }, payload) {
      commit('SET_SORTED_BY', payload)
    },
    async runCheckStatus({ commit }, ids) {
      try {
        const { data } = await axios.put(`/site/${router.currentRoute.params.id}/hardware/status`, { hardwareIds: ids })

        commit('UPDATE_ITEMS_STATUS', data?.data ?? [])
      } catch (error) {
        console.error(error)
      }
    },
  },
}
