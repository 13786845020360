const ROLE_SIMRUSH = 'simrush'
const ROLE_MASTER_DISTRIBUTOR = 'master-distributor'
const ROLE_DISTRIBUTOR = 'distributor'
const ROLE_RESELLER = 'reseller'
const ROLE_CLIENT = 'client'
const ROLE_PRIMARY_ADMIN = 'primary-admin'
const ROLE_ADMIN = 'admin'
const ROLE_EDITOR = 'editor'
const ROLE_READ_ONLY = 'read-only'

export default {
  ROLE_SIMRUSH,
  ROLE_MASTER_DISTRIBUTOR,
  ROLE_DISTRIBUTOR,
  ROLE_RESELLER,
  ROLE_CLIENT,
  ROLE_PRIMARY_ADMIN,
  ROLE_ADMIN,
  ROLE_EDITOR,
  ROLE_READ_ONLY,
}
