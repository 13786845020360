import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import geo from './geo'
import user from './user'
import account from './account'
import dashboard from './dashboard'
import tariffs from './tariffs'
import nas from './nas'
import services from './services'
import register from './register'
import invite from './invite'
import companyManagement from './company-management'
import userManagement from './user-management'
import licence from './licence'

import vpnDialups from './vpn-dialups'
import vpnDialupsSessions from './vpn-dialups-sessions'

import hardware from './hardware'
import hardwareManufacturers from './hardware-manufacturers'
import hardwareModels from './hardware-models'
import hardwareTypes from './hardware-types'

import sites from './sites'
import wifi from './sites/wifi'
import blockList from './sites/block_list'
import dhcp from './sites/dhcp'
import status from './sites/status'
import registeredDevices from './sites/registered_devices'
import lanSettings from './sites/lan_settings'
import accessPoints from './sites/access_points'
import accessControl from './sites/access_control'

import statistics from './statistics'

import ipamIpv4 from './ipam-ipv4'
import ipamIpv6 from './ipam-ipv6'
import ipamCategories from './ipam-subnet-categories'
import ipamLists from './ipam-lists'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    geo,
    user,
    account,
    dashboard,
    tariffs,
    nas,
    services,
    register,
    invite,
    companyManagement,
    userManagement,
    licence,

    vpnDialups,
    vpnDialupsSessions,

    hardware,
    hardwareManufacturers,
    hardwareModels,
    hardwareTypes,

    sites,
    wifi,
    blockList,
    registeredDevices,
    status,
    dhcp,
    statistics,
    lanSettings,
    accessPoints,
    accessControl,

    ipamIpv4,
    ipamIpv6,
    ipamCategories,
    ipamLists,
  },
  strict: process.env.DEV,
})
