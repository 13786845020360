import actionList from './actionList'

const { ACTION_VIEW } = actionList

const SUBJECT_HARDWARE_MODELS = 'hardware-models'
const SUBJECT_HARDWARE_TYPES = 'hardware-types'
const SUBJECT_HARDWARE_MANUFACTURERS = '"hardware-manufacturers"'

export default {
  viewManageHardwareList: [
    {
      action: ACTION_VIEW,
      subject: SUBJECT_HARDWARE_MODELS,
    },
    {
      action: ACTION_VIEW,
      subject: SUBJECT_HARDWARE_TYPES,
    },
    {
      action: ACTION_VIEW,
      subject: SUBJECT_HARDWARE_MANUFACTURERS,
    },
  ],
}
