import actionList from '@/libs/acl_roles/actionList'

const { ACTION_VIEW, ACTION_CREATE, ACTION_UPDATE } = actionList

export default [

  // *===============================================---*
  // *--------- Hardware pages ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/dialups/list',
    name: 'dialups-list',
    component: () => import('@/views/apps/sites/vpn-dialups/Index.vue'),
    meta: {
      resource: 'vpn-dialups',
      action: ACTION_VIEW,
      pageTitle: 'Vpn Dialup Users',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dialups/create',
    name: 'dialups-create',
    component: () => import('@/views/apps/sites/vpn-dialups/Create.vue'),
    meta: {
      resource: 'vpn-dialups',
      action: ACTION_CREATE,
      navActiveLink: 'dialups-list',
      pageTitle: 'Create Vpn Dialup User',
      breadcrumb: [
        {
          text: 'List',
          to: '/dialups/list',
        },
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dialups/update/:id',
    name: 'dialups-update',
    component: () => import('@/views/apps/sites/vpn-dialups/Update.vue'),
    meta: {
      resource: 'vpn-dialups',
      action: ACTION_UPDATE,
      navActiveLink: 'dialups-list',
      pageTitle: 'Update Vpn Dialup User',
      breadcrumb: [
        {
          text: 'List',
          to: '/dialups/list',
        },
        {
          text: 'Update',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dialups/details/:id',
    name: 'dialups-details',
    component: () => import('@/views/apps/sites/vpn-dialups/ShowInfo.vue'),
    meta: {
      resource: 'vpn-dialups',
      action: ACTION_VIEW,
      navActiveLink: 'dialups-list',
      pageTitle: 'Details',
      breadcrumb: [
        {
          text: 'List',
          to: '/dialups/list',
        },
        {
          text: 'Details',
          active: true,
        },
      ],
    },
  },
]
