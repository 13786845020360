import useJwt from '@/auth/jwt/useJwt'
import rulesByRoles from '@/libs/acl_roles'

export default {
  namespaced: true,
  state: {
    userData: JSON.parse(localStorage.getItem('userData') || null),
  },
  getters: {
  },
  mutations: {
    SET_USER_DATA(state, data) {
      state.userData = data
      localStorage.setItem('userData', JSON.stringify(data))
    },
  },
  actions: {
    async login({ commit }, payload) {
      try {
        const { data } = await useJwt.login({
          ...payload,
        })

        const {
          user, abilities, roles, app,
        } = data.data
        const { token } = data.data.access

        abilities.push({
          action: 'read',
          resource: 'dashboard',
        })

        const mergedRules = rulesByRoles(roles, abilities).rules.concat(abilities)

        const userData = {
          ...user,
          ...{ abilities: mergedRules },
          ...{ roles },
        }

        useJwt.setToken(token)

        commit('SET_USER_DATA', userData)
        commit('app/SET_VERSION', app.version, { root: true })

        return {
          abilities: mergedRules,
        }
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async userLogin({ commit }, id) {
      try {
        const { data } = await useJwt.userLogin(id)

        const {
          user, abilities, roles, app,
        } = data.data
        const { token } = data.data.access

        abilities.push({
          action: 'read',
          resource: 'dashboard',
        })

        const mergedRules = rulesByRoles(roles, abilities).rules.concat(abilities)

        const userData = {
          ...user,
          ...{ abilities: mergedRules },
          ...{ roles },
        }

        useJwt.setToken(token)

        commit('SET_USER_DATA', userData)
        commit('app/SET_VERSION', app.version, { root: true })

        return {
          abilities: mergedRules,
        }
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
