import axios from '@axios'
import { getField } from 'vuex-map-fields'

const getDefaultState = () => ({
  items: [],
  optionsSummaryList: [],
})

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    getField,
  },
  mutations: {
    SET_ITEMS(state, payload) {
      state.items = payload
    },
    SET_PARENT_COMPANY_LIST(state, list) {
      state.optionsSummaryList = list
    },
    SET_SITE_LIST(state, list) {
      state.optionsSummaryList = list
    },
    SET_LICENCE_LIST(state, list) {
      state.optionsSummaryList = list
    },
    SET_HARDWARE_LIST(state, list) {
      state.optionsSummaryList = list
    },
    SET_USER_LIST(state, list) {
      state.optionsSummaryList = list
    },
  },
  actions: {
    async getItems({ commit }) {
      try {
        const { data } = await axios.get('/dashboard/summary')
        const cards = data?.data?.cards || data?.data || []
        commit('SET_ITEMS', cards)

        return cards
      } catch (error) {
        commit('SET_ITEMS', [])
        return Promise.reject(error)
      }
    },
    async fetchParentCompanyList({ commit }, payload) {
      try {
        const { data } = await axios.get(`/company/list?query=${payload.query}&parent_id=${payload.id}&type_id=${payload.typeId}`)

        commit('SET_PARENT_COMPANY_LIST', data.data)

        return data.data
      } catch (error) {
        commit('SET_PARENT_COMPANY_LIST', [])
        return Promise.reject(error)
      }
    },
    async fetchSiteList({ commit }, payload) {
      try {
        const { data } = await axios.get(`/site/list?query=${payload.query}&company_type=site&parent_company_id=${payload.companyId}`)

        commit('SET_SITE_LIST', data.data)

        return data.data
      } catch (error) {
        commit('SET_SITE_LIST', [])
        return Promise.reject(error)
      }
    },
    async fetchLicenceList({ commit }, payload) {
      try {
        const { data } = await axios.get(`/licence/list?query=${payload.query}&parent_company_id=${payload.companyId}`)

        commit('SET_LICENCE_LIST', data.data)

        return data.data
      } catch (error) {
        commit('SET_LICENCE_LIST', [])
        return Promise.reject(error)
      }
    },
    async fetchHardwareList({ commit }, payload) {
      try {
        const { data } = await axios.get(`/hardware/list?query=${payload.query}&parent_company_id=${payload.companyId}`)

        commit('SET_HARDWARE_LIST', data.data)

        return data.data
      } catch (error) {
        commit('SET_HARDWARE_LIST', [])
        return Promise.reject(error)
      }
    },
    async fetchUserList({ commit }, payload) {
      try {
        const { data } = await axios.get(`/user/list?query=${payload.query}&parent_company_id=${payload.companyId}`)

        commit('SET_USER_LIST', data.data)

        return data.data
      } catch (error) {
        commit('SET_USER_LIST', [])
        return Promise.reject(error)
      }
    },
  },
}
