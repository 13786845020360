import axios from '@axios'

const getDefaultState = () => ({
  items: [],
  searchFields: '',
  orderBy: '',
  sortedBy: '',
})

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {},
  mutations: {
    SET_ITEMS(state, payload) {
      state.items = payload
    },
    SET_ITEM(state, payload) {
      state.items.push(payload)
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    UPDATE_ITEM(state, payload) {
      state.items = state.items.map(item => {
        if (item.id === payload.id) {
          return payload
        }

        return item
      })
    },
    SET_SEARCH_FIELDS(state, fields) {
      state.searchFields = fields
    },
    SET_ORDER_BY(state, order) {
      state.orderBy = order
    },
    DELETE_ITEM(state, id) {
      state.items = state.items.filter(item => (item.id !== id))
    },
    SET_SORTED_BY(state, field) {
      state.sortedBy = field
    },
  },
  actions: {
    async getItems({ commit }, id) {
      try {
        const { data } = await axios.get(`/site/${id}/wifi/access-control/index`)
        commit('SET_ITEMS', data.data)

        return data.data
      } catch (error) {
        commit('SET_ITEMS', [])
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async createItem({ commit }, { siteId, ...payload }) {
      try {
        const { data } = await axios.post(`/site/${siteId}/wifi/access-control/store`, payload.payload)
        commit('SET_ITEM', data.data)
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateItem({ commit }, { siteId, ...payload }) {
      try {
        const { data } = await axios.put(`/site/${siteId}/wifi/access-control/update`, payload.payload)

        commit('UPDATE_ITEM', data.data)

        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async updateAccessControlStatusEnable({ commit }, { siteId, ids }) {
      try {
        await axios.put(`/site/${siteId}/wifi/access-control/enable`, {
          ids: [...ids],
        })

        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async updateAccessControlStatusDisable({ commit }, { siteId, ids }) {
      try {
        await axios.put(`/site/${siteId}/wifi/access-control/disable`, {
          ids: [...ids],
        })

        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async deleteItem({ commit }, { siteId, ids }) {
      try {
        await axios.delete(`/site/${siteId}/wifi/access-control/destroy`, {
          data: {
            ids: [...ids],
          },
        })

        commit('DELETE_ITEM', ids[0])
        return true
      } catch (error) {
        commit('RESET_ITEM', [])
        return Promise.reject(error)
      }
    },
    setSearchFields({ commit }, payload) {
      commit('SET_SEARCH_FIELDS', payload)
    },
    setOrderBy({ commit }, payload) {
      commit('SET_ORDER_BY', payload)
    },
    setSortedBy({ commit }, payload) {
      commit('SET_SORTED_BY', payload)
    },
  },
}
