import actionList from '@/libs/acl_roles/actionList'

const { ACTION_VIEW, ACTION_CREATE, ACTION_UPDATE } = actionList

export default [
  // *===============================================---*
  // *--------- User management pages ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/company-management/list',
    name: 'company-list',
    component: () => import('@/views/apps/company-management/Index.vue'),
    meta: {
      resource: 'companies',
      action: ACTION_VIEW,
      pageTitle: 'Companies',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company-management/create',
    name: 'company-management-create',
    component: () => import('@/views/apps/company-management/Create.vue'),
    meta: {
      resource: 'companies',
      action: ACTION_CREATE,
      navActiveLink: 'company-list',
      pageTitle: 'Create Company',
      breadcrumb: [
        {
          text: 'List',
          to: '/company-management/list',
        },
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company-management/update/:id',
    name: 'company-management-update',
    component: () => import('@/views/apps/company-management/Update.vue'),
    meta: {
      resource: 'companies',
      action: ACTION_UPDATE,
      navActiveLink: 'company-list',
      pageTitle: 'Update Company',
      breadcrumb: [
        {
          text: 'List',
          to: '/company-management/list',
        },
        {
          text: 'Update',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company-management/summary/:id',
    name: 'company-management-summary',
    component: () => import('@/views/apps/company-management/SummaryPage.vue'),
    meta: {
      resource: 'companies',
      action: ACTION_VIEW,
      navActiveLink: 'company-list',
      pageTitle: 'Company Summary',
      breadcrumb: [
        {
          text: 'List',
          to: '/company-management/list',
        },
        {
          text: 'Summary',
          active: true,
        },
      ],
    },
  },
]
