import axios from '@axios'
import { getField, updateField } from 'vuex-map-fields'

const getDefaultState = () => ({
  subnetList: [],
  typesList: [],
  usageList: [],
  prefixesList: [],
  ipVersionsList: [],
  ipsList: [],
  companyList: [],
  categoryList: [],
})

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    getField,
  },
  mutations: {
    SET_LIST(state, payload) {
      state.subnetList = [...payload]
    },
    SET_TYPES(state, payload) {
      state.typesList = [...payload]
    },
    SET_USAGE(state, payload) {
      state.usageList = [...payload]
    },
    SET_PREFIXES(state, payload) {
      state.prefixesList = [...payload]
    },
    SET_VERSIONS(state, payload) {
      state.ipVersionsList = [...payload]
    },
    SET_IPS(state, payload) {
      state.ipsList = [...payload]
    },
    SET_COMPANIES(state, payload) {
      state.companyList = [...payload]
    },
    SET_CATEGORY(state, payload) {
      state.categoryList = [...payload]
    },
    RESET_ITEM(state) {
      state.item = null
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    updateField,
  },
  actions: {
    async getList({ commit }, payload) {
      try {
        const { data } = await axios.get(`/network/subnet/list?${payload}`)
        commit('SET_LIST', data.data)

        return data.data
      } catch (error) {
        commit('RESET_ITEM', [])
        return Promise.reject(error)
      }
    },
    async getTypes({ commit }) {
      try {
        const { data } = await axios.get('/network/subnet/types/list')
        commit('SET_TYPES', data.data)

        return data.data
      } catch (error) {
        commit('RESET_ITEM', [])
        return Promise.reject(error)
      }
    },
    async getUsage({ commit }) {
      try {
        const { data } = await axios.get('/network/subnet/usage-types/list')
        commit('SET_USAGE', data.data)

        return data.data
      } catch (error) {
        commit('RESET_ITEM', [])
        return Promise.reject(error)
      }
    },
    async getPrefixes({ commit }, payload) {
      try {
        const { data } = await axios.get(`/network/subnet/prefixes/list?${payload}`)
        commit('SET_PREFIXES', data.data)

        return data.data
      } catch (error) {
        commit('RESET_ITEM', [])
        return Promise.reject(error)
      }
    },
    async getVersions({ commit }) {
      try {
        const { data } = await axios.get('/network/subnet/ip-versions/list')
        commit('SET_VERSIONS', data.data)

        return data.data
      } catch (error) {
        commit('RESET_ITEM', [])
        return Promise.reject(error)
      }
    },
    async getIps({ commit }, id) {
      try {
        const { data } = await axios.get(`/network/subnet/${id}/ips/list`)
        commit('SET_IPS', data.data)

        return data.data
      } catch (error) {
        commit('RESET_ITEM', [])
        return Promise.reject(error)
      }
    },
    async getCompanies({ commit }, payload) {
      try {
        const { data } = await axios.get(`/network/subnet/companies/list?query=${payload.query}`)
        commit('SET_COMPANIES', data.data)

        return data.data
      } catch (error) {
        commit('RESET_ITEM', [])
        return Promise.reject(error)
      }
    },
    async getCategories({ commit }) {
      try {
        const { data } = await axios.get('/network/subnet/category/list')
        commit('SET_CATEGORY', data.data)

        return data.data
      } catch (error) {
        commit('RESET_ITEM', [])
        return Promise.reject(error)
      }
    },
  },
}
