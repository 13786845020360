import axios from '@axios'

const getDefaultState = () => ({
  router_ip: null,
  router_dns: null,
  dhcp_dns: null,
  start_end_dhcp: null,
})

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {},
  mutations: {
    SET_ROUTER_IP(state, payload) {
      state.router_ip = { ...payload }
    },
    SET_ROUTER_DNS(state, payload) {
      state.router_dns = { ...payload }
    },
    SET_DHCP_DNS(state, payload) {
      state.dhcp_dns = { ...payload }
    },
    SET_START_END_DHCP(state, payload) {
      state.start_end_dhcp = { ...payload }
    },
  },
  actions: {
    async getRouterIp({ commit }, id) {
      try {
        const { data } = await axios.get(`/hardware/control/${id}/ip/address/index`)
        commit('SET_ROUTER_IP', data.data)

        return data.data
      } catch (error) {
        commit('SET_ROUTER_IP', [])
        return Promise.reject(error)
      }
    },
    async getRouterDns({ commit }, id) {
      try {
        const { data } = await axios.get(`/hardware/control/${id}/ip/dns/index`)
        commit('SET_ROUTER_DNS', data.data)

        return data.data
      } catch (error) {
        commit('SET_ROUTER_DNS', [])
        return Promise.reject(error)
      }
    },
    async getDhcpDns({ commit }, id) {
      try {
        const { data } = await axios.get(`/hardware/control/${id}/ip/dhcp-server/network/index`)
        commit('SET_DHCP_DNS', data.data)

        return data.data
      } catch (error) {
        commit('SET_DHCP_DNS', [])
        return Promise.reject(error)
      }
    },
    async getStartEndDhcp({ commit }, id) {
      try {
        const { data } = await axios.get(`/hardware/control/${id}/ip/pool/index`)
        commit('SET_START_END_DHCP', data.data)

        return data.data
      } catch (error) {
        commit('SET_START_END_DHCP', [])
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async updateRouterIp({ commit }, { id, payload }) {
      try {
        await axios.put(`/hardware/control/${id}/ip/address/update`, payload)
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async createRouterIp({ commit }, { id, payload }) {
      try {
        const { data } = await axios.post(`/hardware/control/${id}/ip/address/store`, payload)
        commit('SET_ROUTER_IP', { id: data.data.id, address: payload })
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async updateRouterDns({ commit }, { id, payload }) {
      try {
        await axios.put(`/hardware/control/${id}/ip/dns/update`, payload)
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async updateStartEndDhcp({ commit }, { id, payload }) {
      try {
        await axios.put(`/hardware/control/${id}/ip/pool/update`, payload)
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async createStartEndDhcp({ commit }, { id, payload }) {
      try {
        const { data } = await axios.post(`/hardware/control/${id}/ip/pool/store`, payload)
        commit('SET_START_END_DHCP', { id: data.data.id, address_start: payload.address_start, address_end: payload.address_end })
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async updateDhcpDns({ commit }, { id, payload }) {
      try {
        await axios.put(`/hardware/control/${id}/ip/dhcp-server/network/update`, payload)
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async createDhcpDns({ commit }, { id, payload }) {
      try {
        const { data } = await axios.post(`/hardware/control/${id}/ip/dhcp-server/network/store`, payload)
        commit('SET_DHCP_DNS', { id: data.data.id, dns_server: payload.dns_server })
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
