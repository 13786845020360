import axios from '@axios'

const getDefaultState = () => ({
  items: [],
  permissions: [],
  item: null,
  searchFields: '',
  orderBy: '',
  sortedBy: '',
  pagination: {
    current_page: 1,
    last_page: 1,
    per_page: 25,
    total: 0,
  },
})

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {},
  mutations: {
    SET_ITEMS(state, payload) {
      state.items = payload
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload
    },
    SET_PERMISSIONS(state, payload) {
      state.permissions = payload
    },
    SET_ITEM(state, payload) {
      state.item = { ...payload }
    },
    UPDATE_ITEM(state, payload) {
      state.items = state.items.map(item => {
        if (item.id === payload.id) {
          return payload
        }

        return item
      })
    },
    DELETE_ITEM(state, id) {
      state.items = state.items.filter(item => (item.id !== id))
    },
    SET_SEARCH_FIELDS(state, fields) {
      state.searchFields = fields
    },
    SET_ORDER_BY(state, order) {
      state.orderBy = order
    },
    SET_SORTED_BY(state, field) {
      state.sortedBy = field
    },
    RESET_ITEM(state) {
      state.item = null
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    async getItems({ commit }, { ...payload }) {
      try {
        const { data } = await axios.get('/hardware/index', { params: { ...payload } })
        const { pagination } = data.meta

        const {
          searchFields, orderBy, sortedBy,
        } = payload

        if (searchFields) {
          commit('SET_SEARCH_FIELDS', searchFields)
        }

        if (orderBy && sortedBy) {
          commit('SET_ORDER_BY', orderBy)
          commit('SET_SORTED_BY', sortedBy)
        }

        commit('SET_ITEMS', data.data)
        commit('SET_PERMISSIONS', data.pemissions)
        commit('SET_PAGINATION', pagination)

        return data.data
      } catch (error) {
        commit('SET_ITEMS', [])
        return Promise.reject(error)
      }
    },
    async getItem({ commit }, id) {
      try {
        const { data } = await axios.get(`/hardware/${id}/show`)
        const {
          name, type, manufacturer, model, serial_number: serialNumber, warranty_expiry_at, readOnly, comment,
        } = data.data
        const warrantyExpiryAt = (new Date(warranty_expiry_at)).toISOString().split('T')[0]
        const polledAt = new Date(readOnly.polled_at).toISOString().replace('T', ' ').replace('Z', '')
          .split('.')[0]
        const result = {
          id,
          name,
          comment,
          serial_number: serialNumber,
          warranty_expiry_at: warrantyExpiryAt,
          type_id: type && type.id ? type.id : null,
          manufacturer_id: manufacturer && manufacturer.id ? manufacturer.id : null,
          model_id: model && model.id ? model.id : null,
          readOnly: { ...readOnly, polled_at: polledAt },
        }

        commit('SET_ITEM', result)

        return data
      } catch (error) {
        commit('RESET_ITEM', [])
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async createItem({ commit }, payload) {
      try {
        await axios.post('/hardware/store', {
          ...payload,
        })

        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async sendFile({ commit }, payload) {
      try {
        await axios.post('/hardware/import', payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getCompany({ commit }, id) {
      try {
        const { data } = await axios.get(`/company/${id}/show`)
        return data.data
      } catch (error) {
        console.log('no commit', commit)
        return Promise.reject(error)
      }
    },
    async updateItem({ commit }, { id, ...payload }) {
      try {
        await axios.put(`/hardware/${id}/update`, {
          ...payload,
        })

        commit('UPDATE_ITEM', payload)

        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async rebootItem({ commit }, id) {
      try {
        await axios.put(`/hardware/${id}/reboot`)

        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async reloadItem({ commit }, id) {
      try {
        const { data } = await axios.patch(`/hardware/${id}/reload`)

        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async deassociateItems({ commit }, { ids }) {
      try {
        await axios.put('/hardware/deassociation', {
          ids,
        })
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async deleteItem({ commit }, id) {
      try {
        await axios.delete(`/hardware/${id}/destroy`)

        commit('DELETE_ITEM', id)
        return true
      } catch (error) {
        commit('RESET_ITEM', [])
        return Promise.reject(error)
      }
    },
    async recoverItem({ commit }, id) {
      try {
        await axios.put(`/hardware/${id}/recover`)

        commit('RESET_ITEM')
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    setSearchFields({ commit }, payload) {
      commit('SET_SEARCH_FIELDS', payload)
    },

    setOrderBy({ commit }, payload) {
      commit('SET_ORDER_BY', payload)
    },
    setSortedBy({ commit }, payload) {
      commit('SET_SORTED_BY', payload)
    },
  },
}
