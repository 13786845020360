import actionList from '@/libs/acl_roles/actionList'

const { ACTION_VIEW, ACTION_CREATE, ACTION_UPDATE } = actionList

export default [

  // *===============================================---*
  // *--------- Hardware pages ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/services/list',
    name: 'services-list',
    component: () => import('@/views/apps/services/Index.vue'),
    meta: {
      resource: 'services',
      action: ACTION_VIEW,
      pageTitle: 'RADIUS Services',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/services/create',
    name: 'services-create',
    component: () => import('@/views/apps/services/Create.vue'),
    meta: {
      resource: 'services',
      action: ACTION_CREATE,
      navActiveLink: 'services-list',
      pageTitle: 'Create Customer Service',
      breadcrumb: [
        {
          text: 'List',
          to: '/services/list',
        },
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },
  {
    path: '/services/update/:id',
    name: 'services-update',
    component: () => import('@/views/apps/services/Update.vue'),
    meta: {
      resource: 'services',
      action: ACTION_UPDATE,
      navActiveLink: 'services-list',
      pageTitle: 'Update Customer Service',
      breadcrumb: [
        {
          text: 'List',
          to: '/services/list',
        },
        {
          text: 'Update',
          active: true,
        },
      ],
    },
  },
]
