const ACTION_VIEW = 'view'
const ACTION_VIEW_LIST = 'view-list'
const ACTION_CREATE = 'create'
const ACTION_UPDATE = 'update'
const ACTION_MANAGE = 'manage'

export default {
  ACTION_VIEW,
  ACTION_VIEW_LIST,
  ACTION_CREATE,
  ACTION_UPDATE,
  ACTION_MANAGE,
}
