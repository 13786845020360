import axios from '@axios'

const getDefaultState = () => ({
  items: [],
  item: null,
  searchFields: '',
  orderBy: '',
  sortedBy: '',
  tariffList: [],
  subnetList: [],
  assignedIp: null,
  pagination: {
    current_page: 1,
    last_page: 1,
    per_page: 25,
    total: 0,
  },
})

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {},
  mutations: {
    SET_ITEMS(state, payload) {
      state.items = payload
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload
    },
    SET_ITEM(state, payload) {
      state.item = { ...payload }
    },
    SET_TARIFFS(state, payload) {
      state.tariffList = [...payload]
    },
    SET_SUBNETS(state, payload) {
      state.subnetList = [...payload]
    },
    SET_ASSIGNED_IP(state, payload) {
      state.assignedIp = payload.address
    },
    UPDATE_ITEM(state, payload) {
      state.items = state.items.map(item => {
        if (item.id === payload.id) {
          return payload
        }

        return item
      })
    },
    SET_SEARCH_FIELDS(state, fields) {
      state.searchFields = fields
    },
    SET_ORDER_BY(state, order) {
      state.orderBy = order
    },
    SET_SORTED_BY(state, field) {
      state.sortedBy = field
    },
    DELETE_ITEM(state, id) {
      state.items = state.items.filter(item => (item.id !== id))
    },
    RESET_ITEM(state) {
      state.item = null
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    async getItems({ commit }, { ...payload }) {
      try {
        const { data } = await axios.get('/network/vpn-dialup/index', { params: { ...payload } })
        const { pagination } = data.meta

        const {
          searchFields, search, orderBy, sortedBy,
        } = payload

        if (searchFields && search) {
          commit('SET_SEARCH_FIELDS', searchFields)
        }

        if (orderBy && sortedBy) {
          commit('SET_ORDER_BY', orderBy)
          commit('SET_SORTED_BY', sortedBy)
        }

        commit('SET_ITEMS', data.data)
        commit('SET_PAGINATION', pagination)

        return data.data
      } catch (error) {
        commit('SET_ITEMS', [])
        return Promise.reject(error)
      }
    },
    async getItem({ commit }, id) {
      try {
        const { data } = await axios.get(`/network/vpn-dialup/${id}/show`)
        commit('SET_ITEM', data.data)

        return data.data
      } catch (error) {
        commit('RESET_ITEM', [])
        return Promise.reject(error)
      }
    },
    async getTariffs({ commit }) {
      try {
        const { data } = await axios.get('/network/tariff/list')
        commit('SET_TARIFFS', data.data)

        return data.data
      } catch (error) {
        commit('RESET_ITEM', [])
        return Promise.reject(error)
      }
    },
    async getSubnets({ commit }) {
      try {
        const { data } = await axios.get('/network/subnet/list?type=end')
        commit('SET_SUBNETS', data.data)

        return data.data
      } catch (error) {
        commit('RESET_ITEM', [])
        return Promise.reject(error)
      }
    },
    async getAssignedIp({ commit }, id) {
      try {
        const { data } = await axios.get(`/network/subnet/${id}/unassigned-ip-address`)
        commit('SET_ASSIGNED_IP', data.data)

        return data.data
      } catch (error) {
        commit('RESET_ITEM', [])
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async createItem({ commit }, payload) {
      try {
        await axios.post('/network/vpn-dialup/store', {
          ...payload,
        })

        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateItem({ commit }, { id, ...payload }) {
      try {
        await axios.put(`/network/vpn-dialup/${id}/update`, {
          ...payload,
        })

        commit('UPDATE_ITEM', payload)

        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async deleteItem({ commit }, id) {
      try {
        await axios.delete(`/network/vpn-dialup/${id}/destroy`)

        commit('DELETE_ITEM', id)
        return true
      } catch (error) {
        commit('RESET_ITEM', [])
        return Promise.reject(error)
      }
    },
    setSearchFields({ commit }, payload) {
      commit('SET_SEARCH_FIELDS', payload)
    },

    setOrderBy({ commit }, payload) {
      commit('SET_ORDER_BY', payload)
    },
    setSortedBy({ commit }, payload) {
      commit('SET_SORTED_BY', payload)
    },
    async recoverItem({ commit }, id) {
      try {
        await axios.put(`/network/vpn-dialup/${id}/recover`)

        commit('RESET_ITEM')
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
