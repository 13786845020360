import actionList from '@/libs/acl_roles/actionList'

const { ACTION_VIEW_INDEX, ACTION_CREATE } = actionList

export default [
  // // *===============================================---*
  // // *--------- Users pages ---- ---------------------------------------*
  // // *===============================================---*
  {
    path: '/invite-user/list',
    name: 'invite-user-list',
    component: () => import('@/views/apps/invite/Index.vue'),
    meta: {
      resource: 'user-invites',
      action: ACTION_VIEW_INDEX,
      pageTitle: 'Invites',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/invite-user',
    name: 'invite-user',
    component: () => import('@/views/apps/invite/Invite.vue'),
    meta: {
      pageTitle: 'Invite User',
      resource: 'user-invites',
      action: ACTION_CREATE,
    },
  },
]
