import axios from '@axios'
import { getField } from 'vuex-map-fields'

const getDefaultState = () => ({
  items: [],
  item: null,
  statuses: [],
  levels: [],
  categories: [],
  permissions: [],
  types: [],
  searchFields: '',
  orderBy: '',
  sortedBy: '',
  pagination: {
    current_page: 1,
    last_page: 1,
    per_page: 25,
    total: 0,
  },
  siteId: null,
})

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    getField,
  },
  mutations: {
    SET_ITEMS(state, payload) {
      state.items = payload
    },
    SET_SITE_ID(state, payload) {
      state.siteId = payload
    },
    SET_PERMISSIONS(state, payload) {
      state.permissions = payload
    },
    SET_ITEM(state, payload) {
      state.item = { ...payload }
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload
    },
    SET_STATUSES(state, payload) {
      state.statuses = payload.map(item => ({
        value: item.id,
        text: item.name,
      }))
    },
    SET_LEVELS(state, payload) {
      state.levels = payload.map(item => ({
        value: item.id,
        text: item.name,
      }))
    },
    SET_CATEGORIES(state, payload) {
      state.categories = payload.map(item => ({
        value: item.id,
        text: item.name,
      }))
    },
    SET_TYPES(state, payload) {
      state.types = payload.map(item => ({
        value: item.id,
        text: item.name,
      }))
    },
    UPDATE_ITEM(state, payload) {
      state.items = state.items.map(item => {
        if (item.id === payload.id) {
          return payload
        }

        return item
      })
    },
    DELETE_ITEM(state, id) {
      state.items = state.items.filter(item => (item.id !== id))
    },
    SET_SEARCH_FIELDS(state, fields) {
      state.searchFields = fields
    },
    SET_ORDER_BY(state, order) {
      state.orderBy = order
    },
    SET_SORTED_BY(state, field) {
      state.sortedBy = field
    },
    RESET_ITEM(state) {
      state.item = null
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    async getItems({ commit }, { ...payload }) {
      try {
        const { data } = await axios.get('/licence/index', { params: { ...payload } })
        const { pagination } = data.meta

        const {
          searchFields, orderBy, sortedBy, siteId,
        } = payload

        if (searchFields) {
          commit('SET_SEARCH_FIELDS', searchFields)
        }

        if (siteId) {
          commit('SET_SITE_ID', siteId)
        }

        if (orderBy && sortedBy) {
          commit('SET_ORDER_BY', orderBy)
          commit('SET_SORTED_BY', sortedBy)
        }

        commit('SET_ITEMS', data.data)
        commit('SET_PERMISSIONS', data.pemissions)
        commit('SET_PAGINATION', pagination)
        commit('SET_STATUSES', data.options.statuses.data)
        commit('SET_LEVELS', data.options.levels.data)
        commit('SET_CATEGORIES', data.options.categories.data)
        commit('SET_TYPES', data.options.types.data)
        return data.data
      } catch (error) {
        commit('SET_ITEMS', [])
        return Promise.reject(error)
      }
    },
    async getCompany({ commit }, id) {
      try {
        const { data } = await axios.get(`/company/${id}/show`)
        return data.data
      } catch (error) {
        console.log('no commit', commit)
        return Promise.reject(error)
      }
    },
    async getItem({ commit }, id) {
      try {
        const { data } = await axios.get(`/licence/${id}/show`)

        commit('SET_ITEM', data.data)

        return data.data
      } catch (error) {
        commit('RESET_ITEM', [])
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async createItem({ commit }, payload) {
      try {
        await axios.post('/licence/store', {
          ...payload,
        })

        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateItem({ commit }, { id, ...payload }) {
      try {
        await axios.put(`/licence/${id}/update`, {
          ...payload,
        })

        commit('UPDATE_ITEM', payload)

        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getOptions({ commit }) {
      try {
        const { data } = await axios.get('/licence/options')
        commit('SET_STATUSES', data.options.statuses.data)
        commit('SET_LEVELS', data.options.levels.data)
        commit('SET_CATEGORIES', data.options.categories.data)
        commit('SET_TYPES', data.options.types.data)
        return data.data
      } catch (error) {
        commit('RESET_ITEM', [])
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async updateLicenceStatus({ commit }, payload) {
      try {
        await axios.put('/licence/change-status', {
          ...payload,
        })

        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async sendReminderEmail({ commit }, id) {
      try {
        await axios.put(`/licence/${id}/send-email`)

        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    setSearchFields({ commit }, payload) {
      commit('SET_SEARCH_FIELDS', payload)
    },
    setSideId({ commit }, payload) {
      commit('SET_SITE_ID', payload)
    },
    setOrderBy({ commit }, payload) {
      commit('SET_ORDER_BY', payload)
    },
    setSortedBy({ commit }, payload) {
      commit('SET_SORTED_BY', payload)
    },
    async deleteItem({ commit }, id) {
      try {
        await axios.delete(`/licence/${id}/destroy`)

        commit('DELETE_ITEM', id)
        return true
      } catch (error) {
        commit('RESET_ITEM', [])
        return Promise.reject(error)
      }
    },
    async recoverItem({ commit }, id) {
      try {
        await axios.put(`/licence/${id}/recover`)

        commit('RESET_ITEM')
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
