export default [
  // *===============================================---*
  // *--------- Profile pages ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/apps/profile/view/View.vue'),
    meta: {
      pageTitle: 'Profile',
    },
  },
  {
    path: '/profile/update-info',
    name: 'profile-update-info',
    component: () => import('@/views/apps/profile/edit/UpdateInfo.vue'),
    meta: {
      pageTitle: 'Update info',
      breadcrumb: [
        {
          text: 'Profile',
          to: '/profile',
          pageTitle: 'Models',
        },
        {
          text: 'Update',
          active: true,
        },
      ],
    },
  },
]
