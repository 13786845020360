import Vue from 'vue'

// axios
import axios from 'axios'

// axios.defaults.withCredentials = true

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_URL,
  // timeout: 2000,
  headers: { 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept' },
})

axiosIns.defaults.withCredentials = true

Vue.prototype.$http = axiosIns

export default axiosIns
