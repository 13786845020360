import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

// Routes
import apps from './routes/apps'
import authentication from './routes/authentication'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...apps,
    ...authentication,
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  const isPublicRoute = to.meta.resource === 'Auth'
  const isErrorPage = to.name === 'error-404'
  const isLegal = to.path.includes('/legal') || to.path.includes('/latest')

  document.title = to.meta.pageTitle ? `NetPanel | ${to.meta.pageTitle}` : 'NetPanel'

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })
    return next({ name: 'error-404' })
  }

  if (!isPublicRoute && !isLoggedIn && !isErrorPage && !isLegal) return next({ name: 'auth-login' })

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
