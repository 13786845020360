import axios from '@axios'

const getDefaultState = () => ({
  items: [],
  tableTypes: [],
  statuses: [],
  billingTerms: [],
  countries: [],
  currencies: [],
  defaultIso: null,
  types: [],
  item: null,
  searchFields: '',
  orderBy: '',
  sortedBy: '',
  summary: null,
  pagination: {
    current_page: 1,
    last_page: 1,
    per_page: 25,
    total: 0,
  },
  companyStatistics: [],
})

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {},
  mutations: {
    SET_ITEMS(state, payload) {
      state.items = payload
    },
    SET_BILLING_TERMS(state, payload) {
      state.billingTerms = payload
    },
    SET_COUNTRIES(state, payload) {
      state.countries = payload
    },
    SET_CURRENCIES(state, payload) {
      state.currencies = payload
    },
    SET_TYPES(state, payload) {
      state.types = payload
    },
    SET_DEFAULT_ISO(state, payload) {
      state.defaultIso = payload
    },
    SET_TABLE_TYPES(state, payload) {
      state.tableTypes = payload.data.map(item => ({
        value: item.id,
        text: item.name,
      }))
    },
    SET_STATUSES(state, payload) {
      state.statuses = payload.data.map(item => ({
        value: item.id,
        text: item.name,
      }))
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload
    },
    SET_ITEM(state, payload) {
      state.item = { ...payload }
    },
    UPDATE_ITEM(state, payload) {
      state.items = state.items.map(item => {
        if (item.id === payload.id) {
          return payload
        }

        return item
      })
    },
    SET_ITEM_SUMMARY(state, payload) {
      state.summary = { ...payload }
    },
    DELETE_ITEM(state, id) {
      state.items = state.items.filter(item => (item.id !== id))
    },
    SET_SEARCH_FIELDS(state, fields) {
      state.searchFields = fields
    },
    SET_ORDER_BY(state, order) {
      state.orderBy = order
    },
    SET_SORTED_BY(state, field) {
      state.sortedBy = field
    },
    RESET_ITEM(state) {
      state.item = null
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    SET_COMPANY_STATISTICS(state, payload) {
      state.companyStatistics = payload
    },
  },
  actions: {
    async getItems({ commit }, { ...payload }) {
      try {
        const { data } = await axios.get('/company/index', { params: { ...payload } })
        const { pagination } = data.meta
        const items = data.data.map(item => ({
          ...item,
          ...{ telephone: `${item.phone_country_code ? `+${item.phone_country_code}` : ''}${item.phone_number ?? ''}` },
        }))
        const { options } = data
        const {
          searchFields, search, orderBy, sortedBy,
        } = payload

        if (searchFields && search) {
          commit('SET_SEARCH_FIELDS', searchFields)
        }

        if (orderBy && sortedBy) {
          commit('SET_ORDER_BY', orderBy)
          commit('SET_SORTED_BY', sortedBy)
        }

        commit('SET_ITEMS', items)
        commit('SET_TABLE_TYPES', options.types)
        commit('SET_STATUSES', options.statuses)
        commit('SET_PAGINATION', pagination)

        return data.data
      } catch (error) {
        commit('SET_ITEMS', [])
        return Promise.reject(error)
      }
    },
    async getCompany({ commit }, id) {
      try {
        const { data } = await axios.get(`/company/${id}/show`)
        return data.data
      } catch (error) {
        console.log('no commit', commit)
        return Promise.reject(error)
      }
    },

    async getItem({ commit }, id) {
      try {
        const { data } = await axios.get(`/company/${id}/show`)

        commit('SET_ITEM', data.data)
        commit('SET_DEFAULT_ISO', data.data.countyIsoCode)
        commit('SET_BILLING_TERMS', data.options.billingTerms.data)
        commit('SET_COUNTRIES', data.options.countries.data)
        commit('SET_CURRENCIES', data.options.currencies.data)
        commit('SET_TYPES', data.options.types.data)
        return data.data
      } catch (error) {
        commit('RESET_ITEM', [])
        return Promise.reject(error)
      }
    },
    async getOptions({ commit }) {
      try {
        const { data } = await axios.get('/company/options')
        commit('SET_DEFAULT_ISO', data.data.countyIsoCode)
        commit('SET_BILLING_TERMS', data.options.billingTerms.data)
        commit('SET_COUNTRIES', data.options.countries.data)
        commit('SET_CURRENCIES', data.options.currencies.data)
        commit('SET_TYPES', data.options.types.data)
        return data.data
      } catch (error) {
        commit('RESET_ITEM', [])
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async createItem({ commit }, payload) {
      try {
        await axios.post('/company/store', {
          ...payload,
        })

        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateItem({ commit }, { id, payload }) {
      try {
        await axios.put(`/company/${id}/update`, {
          ...payload,
        })

        commit('UPDATE_ITEM', payload)

        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async changeItemsStatuses({ commit }, { ids, is_active }) {
      try {
        await axios.put('/company/change-status', {
          ids,
          is_active,
        })
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async deleteItem({ commit }, id) {
      try {
        await axios.delete(`/company/${id}/destroy`)

        commit('DELETE_ITEM', id)

        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getSummary({ commit }, id) {
      try {
        const { data } = await axios.get(`/company/${id}/summary`)
        commit('SET_ITEM_SUMMARY', data.data)

        return data.data
      } catch (error) {
        commit('RESET_ITEM', [])
        return Promise.reject(error)
      }
    },
    setSearchFields({ commit }, payload) {
      commit('SET_SEARCH_FIELDS', payload)
    },

    setOrderBy({ commit }, payload) {
      commit('SET_ORDER_BY', payload)
    },
    setSortedBy({ commit }, payload) {
      commit('SET_SORTED_BY', payload)
    },
    async getCompanyStatistics({ commit }, id) {
      try {
        const { data } = await axios.get(`/dashboard/summary/${id}`)
        const cards = data?.data?.cards || []
        commit('SET_COMPANY_STATISTICS', cards)

        return cards
      } catch (error) {
        commit('SET_COMPANY_STATISTICS', [])
        return Promise.reject(error)
      }
    },
    async recoverItem({ commit }, id) {
      try {
        await axios.put(`/company/${id}/recover`)

        commit('RESET_ITEM')
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
