import axios from '@axios'

const getDefaultState = () => ({
  items: [],
})

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {},
  mutations: {
    SET_ITEMS(state, payload) {
      state.items = payload
    },
    SET_ITEM(state, payload) {
      state.items.push(payload)
    },
    UPDATE_ITEM(state, payload) {
      state.items = state.items.map(item => {
        if (item.id === payload.id) {
          return payload
        }

        return item
      })
    },
    DELETE_ITEM(state, id) {
      state.items = state.items.filter(item => (item.id !== id))
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    async getItems({ commit }, id) {
      try {
        const { data } = await axios.get(`/hardware/control/${id}/caps-man/access-list/index`)
        commit('SET_ITEMS', data.data)

        return data.data
      } catch (error) {
        commit('SET_ITEMS', [])
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async createItem({ commit }, { id, payload }) {
      try {
        const { data } = await axios.post(`/hardware/control/${id}/caps-man/access-list/store`, {
          ...payload,
        })
        commit('SET_ITEM', {
          ...payload,
          id: data.data.id,
        })
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateItem({ commit }, { id, payload }) {
      try {
        await axios.put(`/hardware/control/${id}/caps-man/access-list/update`, {
          ...payload,
        })

        commit('UPDATE_ITEM', payload)

        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async deleteItem({ commit }, { id, hardwareId }) {
      const params = new URLSearchParams()
      params.append('id', id)
      try {
        await axios.delete(`/hardware/control/${hardwareId}/caps-man/access-list/destroy`, { params }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })

        commit('DELETE_ITEM', id)
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
