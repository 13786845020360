import { defineAbility } from '@casl/ability'
import roles from './rolesList'
import rolesPermission from './rolesPermission'
import abilityListAPI from './abilityListAPI'

export default function rulesByRoles(userRoles, userAbilities) {
  return defineAbility((can, cannot, rules) => {
    const checkRole = role => userRoles.some(userRole => userRole === role)
    const canViewManageHardware = userAbilities.some(ability => abilityListAPI.viewManageHardwareList.some(item => item.subject === ability.subject))

    if (canViewManageHardware) {
      can('view', rolesPermission.MANAGE_HARDWARE)
    }

    if (checkRole(roles.ROLE_SIMRUSH)) {
      can('view', rolesPermission.HARDWARE_SELECT_ANY_WARRANTY_EXPIRY_AT)

      can('view', rolesPermission.SELECT_MASTER_DISTRIBUTOR)
      can('view', rolesPermission.SELECT_DISTRIBUTOR)
      can('view', rolesPermission.SELECT_RESELLER)
      can('view', rolesPermission.SELECT_CLIENT)

      can('view', rolesPermission.VIEW_MASTER_DISTRIBUTOR_SELECT)
      can('view', rolesPermission.VIEW_DISTRIBUTOR_SELECT)
      can('view', rolesPermission.VIEW_RESELLER_SELECT)
    }

    if (checkRole(roles.ROLE_MASTER_DISTRIBUTOR)) {
      can('view', rolesPermission.SELECT_DISTRIBUTOR)
      can('view', rolesPermission.SELECT_RESELLER)
      can('view', rolesPermission.SELECT_CLIENT)

      can('view', rolesPermission.VIEW_DISTRIBUTOR_SELECT)
      can('view', rolesPermission.VIEW_RESELLER_SELECT)
    }

    if (checkRole(roles.ROLE_DISTRIBUTOR)) {
      can('view', rolesPermission.SELECT_RESELLER)
      can('view', rolesPermission.SELECT_CLIENT)

      can('view', rolesPermission.VIEW_RESELLER_SELECT)
    }

    if (checkRole(roles.ROLE_RESELLER)) {
      can('view', rolesPermission.SELECT_CLIENT)
    }

    if (checkRole(roles.ROLE_CLIENT)) {
      // can('manage', 'all')
    }

    if (checkRole(roles.ROLE_PRIMARY_ADMIN)) {
    //   can('manage', 'all')
    }

    if (checkRole(roles.ROLE_ADMIN)) {
    //   can('manage', 'all')
    }

    if (checkRole(roles.ROLE_EDITOR)) {
    //   can('manage', 'all')
    }

    if (checkRole(roles.ROLE_READ_ONLY)) {
    //   can('manage', 'all')
    }

    return rules
  })
}
