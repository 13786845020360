/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import axios from '@axios'

const getDefaultState = () => ({
  items: [],
  searchFields: '',
  orderBy: '',
  sortedBy: '',
  pagination: {
    current_page: 1,
    last_page: 1,
    per_page: 25,
    total: 0,
  },
})

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {},
  mutations: {
    SET_ITEMS(state, payload) {
      state.items = payload
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload
    },
    SET_SEARCH_FIELDS(state, fields) {
      state.searchFields = fields
    },
    SET_ORDER_BY(state, order) {
      state.orderBy = order
    },
    SET_SORTED_BY(state, field) {
      state.sortedBy = field
    },
    DELETE_ITEM(state, payload) {
      if (!payload.id) {
        state.items = state.items.filter(item => (item.id !== payload))
      } else {
        state.items = state.items.map(item => {
          if (item.children) {
            return { ...item, children: item.children.filter(itemEnd => (itemEnd.id !== payload.id)) }
          }
          return item
        })
      }
    },
    RESET_ITEM(state) {
      state.item = null
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    async getItems({ commit }, { ...payload }) {
      try {
        const { data } = await axios.get('/network/subnet/index?&ip_version=ipv6', { params: { ...payload } })
        const { pagination } = data.meta
        const {
          searchFields, search, orderBy, sortedBy,
        } = payload
        data.data.map(item => {
          if (item.ends && item.ends.length > 0) {
            return item.children = [...item.ends]
          }
          return item.ends
        })
        if (searchFields && search) {
          commit('SET_SEARCH_FIELDS', searchFields)
        }

        if (orderBy && sortedBy) {
          commit('SET_ORDER_BY', orderBy)
          commit('SET_SORTED_BY', sortedBy)
        }

        commit('SET_ITEMS', data.data)
        commit('SET_PAGINATION', pagination)

        return data.data
      } catch (error) {
        commit('SET_ITEMS', [])
        return Promise.reject(error)
      }
    },
    async deleteItem({ commit }, payload) {
      try {
        if (!payload.id) {
          await axios.delete(`/network/subnet/${payload}/destroy`)
        } else {
          await axios.delete(`/network/subnet/${payload.id}/destroy`)
        }
        commit('DELETE_ITEM', payload)
        return true
      } catch (error) {
        commit('RESET_ITEM', [])
        return Promise.reject(error)
      }
    },
    async recoverItem({ commit }, id) {
      try {
        await axios.put(`/network/subnet/${id}/recover`)

        commit('RESET_ITEM')
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    setSearchFields({ commit }, payload) {
      commit('SET_SEARCH_FIELDS', payload)
    },

    setOrderBy({ commit }, payload) {
      commit('SET_ORDER_BY', payload)
    },
    setSortedBy({ commit }, payload) {
      commit('SET_SORTED_BY', payload)
    },
  },
}
