<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import useJwt from '@/auth/jwt/useJwt'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  watch: {
    async $route(to) {
      if (to.meta.resource === 'Auth' && localStorage.getItem('userData')) {
        await this.$http.post('/auth/logout')
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
        localStorage.removeItem('userData')
      }
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss">
  .action-th-width {
    width: 100px!important;
  }
  [dir] table.vgt-table td,
  [dir] table.vgt-table thead th {
    font-size: 14px;
  }

  table.vgt-table {
    .dropdown-menu .dropdown-item {
      font-size: 14px;
    }
  }

  [dir] .vue-tel-input {
    border: 1px solid #d8d6de!important;
    border-radius: 0.357rem!important;
  }
  [dir=ltr] .vti__input::placeholder {
    color: #b9b9c3;
  }
  [dir] .vti__dropdown-arrow {
        margin-left: 5px;
  }
  [dir] .vti__dropdown-list {
    z-index: 2;
  }

  [dir] .dark-layout .vue-tel-input {
    background-color: #283046;
    border-color: #404656!important;
    & input {
      background-color: #283046;
      color: #b4b7bd;
    }
    &:focus-within {
      box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
      border-color: #F0554E !important;
    }
    .vti__dropdown:hover,
    .vti__dropdown.disabled,
    .vti__dropdown.open {
         background-color: #283046;
    }
    .vti__selection .vti__country-code {
       color: #b4b7bd;
    }
    .vti__dropdown-list {
        background-color: #283046;
        border-color: #F0554E;
    }
    .vti__dropdown-item {
      color: #b4b7bd;
      &.highlighted {
        background-color: #5e50ee;
      }
    }
    .vti__dropdown-item.last-preferred {
      border-color: #404656;
    }
  }
  [dir] .vti__dropdown-item {
    font-size: 12px;
  }
</style>
