export default [
  // *===============================================---*
  // *--------- Sites pages ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/sites/list',
    name: 'sites-list',
    component: () => import('@/views/apps/sites/Index.vue'),
    meta: {
      resource: 'sites',
      action: 'view',
      pageTitle: 'Sites Management',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sites/create',
    name: 'sites-create',
    component: () => import('@/views/apps/sites/Create.vue'),
    meta: {
      resource: 'sites',
      action: 'create',
      pageTitle: 'Create site',
      navActiveLink: 'sites-list',
      breadcrumb: [
        {
          text: 'List',
          to: '/sites/list',
        },
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sites/update/:id',
    name: 'site-update',
    component: () => import('@/views/apps/sites/Update.vue'),
    meta: {
      // resource: 'site-update',
      // action: 'update',
      navActiveLink: 'sites-list',
      pageTitle: 'Update site',
      breadcrumb: [
        {
          text: 'List',
          to: '/sites/list',
        },
        {
          text: 'Update',
          active: true,
        },
      ],
    },
  },
  {
    path: '/site/:id/hardware/index',
    name: 'site-summary',
    component: () => import('@/views/apps/sites/InfoPage.vue'),
    meta: {
      resource: 'sites',
      action: 'view',
      navActiveLink: 'sites-list',
      pageTitle: 'Site info',
      breadcrumb: [
        {
          text: 'List',
          to: '/sites/list',
        },
        {
          text: 'Info',
          active: true,
        },
      ],
    },
  },
  {
    path: '/site/:id/hardware/lte/:hardwareId',
    name: 'lte-hardware-summary',
    component: () => import('@/views/apps/hardware/lte/Index.vue'),
    meta: {
      resource: 'sites',
      action: 'view',
      navActiveLink: 'sites-list',
      pageTitle: 'LTE Hardware',
      breadcrumb: [
        {
          text: 'List',
          to: '/sites/list',
        },
        {
          text: 'LTE Hardware',
          active: true,
        },
      ],
    },
  },
  {
    path: '/site/:id/hardware/network/add',
    name: 'site-summary-add-network',
    component: () => import('@/views/apps/sites/network/AddNetwork.vue'),
    meta: {
      resource: 'sites',
      action: 'view',
      navActiveLink: 'sites-list',
      pageTitle: 'Site info',
      breadcrumb: [
        {
          text: 'List',
          to: '/sites/list',
        },
        {
          text: 'Add Network',
          active: true,
        },
      ],
    },
  },
  {
    path: '/site/:id/hardware/network/update/:networkId',
    name: 'site-summary-update-network',
    component: () => import('@/views/apps/sites/network/UpdateNetwork.vue'),
    meta: {
      resource: 'sites',
      action: 'view',
      navActiveLink: 'sites-list',
      pageTitle: 'Update Network',
      breadcrumb: [
        {
          text: 'List',
          to: '/sites/list',
        },
        {
          text: 'Update Network',
          active: true,
        },
      ],
    },
  },
  {
    path: '/site/:id/hardware/dhcp/add',
    name: 'site-summary-add-dhcp',
    component: () => import('@/views/apps/sites/network/AddDHCP.vue'),
    meta: {
      resource: 'sites',
      action: 'view',
      navActiveLink: 'sites-list',
      pageTitle: 'Add DHCP Server',
      breadcrumb: [
        {
          text: 'List',
          to: '/sites/list',
        },
        {
          text: 'Add DHCP Server',
          active: true,
        },
      ],
    },
  },
  {
    path: '/site/:id/hardware/active-leases/add/:leaseId',
    name: 'site-summary-add-active-leases',
    component: () => import('@/views/apps/sites/network/AddActiveLeases.vue'),
    meta: {
      resource: 'sites',
      action: 'view',
      navActiveLink: 'sites-list',
      pageTitle: 'Add DHCP Reservation',
      breadcrumb: [
        {
          text: 'List',
          to: '/sites/list',
        },
        {
          text: 'Add Active Lease',
          active: true,
        },
      ],
    },
  },
  {
    path: '/site/:id/hardware/active-leases/add',
    name: 'site-summary-add-active-leases-empty',
    component: () => import('@/views/apps/sites/network/AddActiveLeases.vue'),
    meta: {
      resource: 'sites',
      action: 'view',
      navActiveLink: 'sites-list',
      pageTitle: 'Add DHCP Reservation',
      breadcrumb: [
        {
          text: 'List',
          to: '/sites/list',
        },
        {
          text: 'Add Active Lease',
          active: true,
        },
      ],
    },
  },
  {
    path: '/site/:id/hardware/dhcp/update/:dhcpId',
    name: 'site-summary-update-dhcp',
    component: () => import('@/views/apps/sites/network/UpdateDHCP.vue'),
    meta: {
      resource: 'sites',
      action: 'view',
      navActiveLink: 'sites-list',
      pageTitle: 'Update DHCP Server',
      breadcrumb: [
        {
          text: 'List',
          to: '/sites/list',
        },
        {
          text: 'Update DHCP Server',
          active: true,
        },
      ],
    },
  },
  {
    path: '/site/:id/hardware/active-leases/update/:leaseId',
    name: 'site-summary-update-active-leases',
    component: () => import('@/views/apps/sites/network/UpdateActiveLeases.vue'),
    meta: {
      resource: 'sites',
      action: 'view',
      navActiveLink: 'sites-list',
      pageTitle: 'Update DHCP Reservation',
      breadcrumb: [
        {
          text: 'List',
          to: '/sites/list',
        },
        {
          text: 'Update Active Lease',
          active: true,
        },
      ],
    },
  },
  {
    path: '/site/:id/hardware/blocked-wifi/create',
    name: 'site-summary-add-blocked-device',
    component: () => import('@/views/apps/sites/wifi/block-wifi/AddBlockWifi.vue'),
    meta: {
      resource: 'sites',
      action: 'view',
      navActiveLink: 'sites-list',
      pageTitle: 'Add Blocked Device',
      breadcrumb: [
        {
          text: 'List',
          to: '/sites/list',
        },
        {
          text: 'Add Blocked Device',
          active: true,
        },
      ],
    },
  },
  {
    path: '/site/:id/hardware/blocked-wifi/update/:blockedWifiId',
    name: 'site-summary-edit-blocked-device',
    component: () => import('@/views/apps/sites/wifi/block-wifi/EditBlockWifi.vue'),
    meta: {
      resource: 'sites',
      action: 'view',
      navActiveLink: 'sites-list',
      pageTitle: 'Edit Blocked Device',
      breadcrumb: [
        {
          text: 'List',
          to: '/sites/list',
        },
        {
          text: 'Edit Blocked Device',
          active: true,
        },
      ],
    },
  },
  {
    path: '/site/:id/hardware/firewall-nat/add',
    name: 'site-summary-add-firewall-nat',
    component: () => import('@/views/apps/sites/firewall/FirewallNatAdd.vue'),
    meta: {
      resource: 'sites',
      action: 'view',
      navActiveLink: 'sites-list',
      pageTitle: 'Add NAT Rule',
      breadcrumb: [
        {
          text: 'List',
          to: '/sites/list',
        },
        {
          text: 'Add NAT Rule',
          active: true,
        },
      ],
    },
  },
  {
    path: '/site/:id/hardware/firewall-nat/:natId',
    name: 'site-summary-edit-firewall-nat',
    component: () => import('@/views/apps/sites/firewall/FirewallNatEdit.vue'),
    meta: {
      resource: 'sites',
      action: 'view',
      navActiveLink: 'sites-list',
      pageTitle: 'Edit NAT Rule',
      breadcrumb: [
        {
          text: 'List',
          to: '/sites/list',
        },
        {
          text: 'Edit NAT Rule',
          active: true,
        },
      ],
    },
  },
  {
    path: '/site/:id/hardware/firewall-list/:listId',
    name: 'site-summary-edit-firewall-list',
    component: () => import('@/views/apps/sites/firewall/FirewallListEdit.vue'),
    meta: {
      resource: 'sites',
      action: 'view',
      navActiveLink: 'sites-list',
      pageTitle: 'Edit Firewall List',
      breadcrumb: [
        {
          text: 'List',
          to: '/sites/list',
        },
        {
          text: 'Edit Firewall List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/site/:id/hardware/wifi/add',
    name: 'site-summary-add-wifi',
    component: () => import('@/views/apps/sites/wifi/wifi-settings/AddWifi.vue'),
    meta: {
      resource: 'sites',
      action: 'view',
      navActiveLink: 'sites-list',
      pageTitle: 'Site info',
      breadcrumb: [
        {
          text: 'List',
          to: '/sites/list',
        },
        {
          text: 'Add WiFi Network',
          active: true,
        },
      ],
    },
  },
  {
    path: '/site/:id/hardware/wifi/:wifiId',
    name: 'site-summary-edit-wifi',
    component: () => import('@/views/apps/sites/wifi/wifi-settings/UpdateWifi.vue'),
    meta: {
      resource: 'sites',
      action: 'view',
      navActiveLink: 'sites-list',
      pageTitle: 'Site info',
      breadcrumb: [
        {
          text: 'List',
          to: '/sites/list',
        },
        {
          text: 'Edit WiFi Network',
          active: true,
        },
      ],
    },
  },
]
