// First part is entity
// Second part is action
// Third part is entity (optional)

// *===============================================---*
// *--------- SITES ---- ---------------------------------------*
// *===============================================---*
const SELECT_MASTER_DISTRIBUTOR = 'select_master_distributor'
const SELECT_DISTRIBUTOR = 'select_distributor'
const SELECT_RESELLER = 'select_reseller'
const SELECT_CLIENT = 'select_client'
const SELECT_SITE = 'select_site'

const VIEW_MASTER_DISTRIBUTOR_SELECT = 'invite_view_master_distributor_select'
const VIEW_DISTRIBUTOR_SELECT = 'invite_view_distributor_select'
const VIEW_RESELLER_SELECT = 'invite_view_reseller_select'

const HARDWARE_SELECT_ANY_WARRANTY_EXPIRY_AT = 'hardware_select_any_warranty_expiry_at'

const MANAGE_HARDWARE = 'manage-hardware'

export default {
  SELECT_MASTER_DISTRIBUTOR,
  SELECT_DISTRIBUTOR,
  SELECT_RESELLER,
  SELECT_CLIENT,
  SELECT_SITE,

  VIEW_MASTER_DISTRIBUTOR_SELECT,
  VIEW_DISTRIBUTOR_SELECT,
  VIEW_RESELLER_SELECT,

  HARDWARE_SELECT_ANY_WARRANTY_EXPIRY_AT,

  MANAGE_HARDWARE,
}
