export default [

  // *===============================================---*
  // *--------- Hardware pages ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/hardware/list',
    name: 'hardware-list',
    component: () => import('@/views/apps/hardware/Index.vue'),
    meta: {
      resource: 'hardware',
      action: 'view',
      pageTitle: 'Site Hardware',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/hardware/create',
    name: 'hardware-create',
    component: () => import('@/views/apps/hardware/Create.vue'),
    meta: {
      resource: 'hardware',
      action: 'create',
      navActiveLink: 'hardware-list',
      pageTitle: 'Create site hardware',
      breadcrumb: [
        {
          text: 'List',
          to: '/hardware/list',
          pageTitle: 'Models',
        },
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },
  {
    path: '/hardware/upload',
    name: 'hardware-upload',
    component: () => import('@/views/apps/hardware/ImportCsv.vue'),
    meta: {
      resource: 'hardware',
      action: 'import',
      navActiveLink: 'hardware-list',
      pageTitle: 'Import hardware',
      breadcrumb: [
        {
          text: 'List',
          to: '/hardware/list',
          pageTitle: 'Models',
        },
        {
          text: 'Import',
          active: true,
        },
      ],
    },
  },
  {
    path: '/hardware/update/:id',
    name: 'hardware-update',
    component: () => import('@/views/apps/hardware/Update.vue'),
    meta: {
      resource: 'hardware',
      action: 'update',
      navActiveLink: 'hardware-list',
      pageTitle: 'Update site hardware',
      breadcrumb: [
        {
          text: 'List',
          to: '/hardware/list',
        },
        {
          text: 'Update',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- Hardware Models pages ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/hardware/models/list',
    name: 'hardware-models-list',
    component: () => import('@/views/apps/hardware/models/Index.vue'),
    meta: {
      resource: 'hardware-models',
      action: 'view',
      pageTitle: 'Models',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/hardware/models/create',
    name: 'hardware-models-create',
    component: () => import('@/views/apps/hardware/models/Create.vue'),
    meta: {
      action: 'create',
      resource: 'hardware-models',
      pageTitle: 'Create model',
      navActiveLink: 'hardware-models-list',
      breadcrumb: [
        {
          text: 'List',
          to: '/hardware/models/list',
          pageTitle: 'Models',
        },
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },
  {
    path: '/hardware/models/update/:id',
    name: 'hardware-models-update',
    component: () => import('@/views/apps/hardware/models/Update.vue'),
    meta: {
      resource: 'hardware-models',
      action: 'update',
      pageTitle: 'Update model',
      navActiveLink: 'hardware-models-list',
      breadcrumb: [
        {
          text: 'List',
          to: '/hardware/models/list',
        },
        {
          text: 'Update',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- Hardware Manufacturer pages ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/hardware/manufacturers/list',
    name: 'hardware-manufacturer-list',
    component: () => import('@/views/apps/hardware/manufacturers/Index.vue'),
    meta: {
      resource: 'hardware-manufacturers',
      action: 'view',
      pageTitle: 'Manufacturers',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/hardware/manufacturers/create',
    name: 'hardware-manufacturer-create',
    component: () => import('@/views/apps/hardware/manufacturers/Create.vue'),
    meta: {
      resource: 'hardware-manufacturers',
      action: 'create',
      pageTitle: 'Create manufacturer',
      navActiveLink: 'hardware-manufacturer-list',
      breadcrumb: [
        {
          text: 'List',
          to: '/hardware/manufacturers/list',
        },
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },
  {
    path: '/hardware/manufacturers/update/:id',
    name: 'hardware-manufacturer-update',
    component: () => import('@/views/apps/hardware/manufacturers/Update.vue'),
    meta: {
      resource: 'hardware-manufacturers',
      action: 'update',
      pageTitle: 'Update manufacturer',
      navActiveLink: 'hardware-manufacturer-list',
      breadcrumb: [
        {
          text: 'List',
          to: '/hardware/manufacturers/list',
        },
        {
          text: 'Update',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- Hardware DPK Page ---- ---------------------------------------*
  // *===============================================---*

  {
    path: '/hardware/dpk',
    name: 'hardware-dpk-files',
    component: () => import('@/views/apps/hardware/dpk/Index.vue'),
    meta: {
      resource: 'hardware-manufacturers',
      action: 'view',
      pageTitle: '.DPK files',
    },
  },
]
